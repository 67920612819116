document.querySelectorAll('button.video--button').forEach(thumbnail => {
    thumbnail.addEventListener('click', () => {
        const body = document.querySelector('body')
        const id = thumbnail.getAttribute('data-trigger-id')
        const videoContainer = thumbnail.parentElement.querySelector('div.video--container[data-id="' + id + '"]')
        console.log(videoContainer)
        const video = videoContainer.querySelector('video')

        body.style.overflow = 'hidden'
        videoContainer.classList.add('active')
        video.currentTime = 0
        video.play()

        videoContainer.querySelector('button#videoClose').addEventListener('click', () => {
            body.style.overflow = ''
            videoContainer.classList.remove('active')
            video.pause()
        })
    })
})
