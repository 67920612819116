const toggleMenu = document.querySelector('.toggle--burger')
const navigationDefault = document.querySelector('.navigation--header')
const navPoint = document.querySelectorAll('.navigation--header a.navigation--listitem--link')

toggleMenu.addEventListener('click', () => {
    toggleMenu.classList.toggle('active')
    navigationDefault.classList.toggle('active')
})

navPoint.forEach(item => {
    item.addEventListener('click', () => {
        toggleMenu.classList.remove('active')
        navigationDefault.classList.remove('active')
    })
})

if(window.location.pathname == '/' || window.location.pathname == '/en') {
    document.querySelector('footer .home--listitem').classList.add('current')
}
