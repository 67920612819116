export function UploadField(el, optionsOverwrite) {
    const defaultOptions = {
        selectorItem: ".field--upload-dropzone",
        selectorInput: ".field--upload-input",
        selectorFileList: ".field--upload-filelist",
        selectorFileTemplate: ".field--upload-filetemplate",
        selectorFileName: ".field--upload-filename",
        selectorFileSize: ".field--upload-filesize",
    };

    const options = Object.assign({}, defaultOptions, optionsOverwrite);
    let fileUpload = el.querySelector(options.selectorInput);
    const uploadButton = el.querySelector(options.selectorItem);
    const selectedFilesList = el.querySelector(options.selectorFileList);
    const fileTemplate = el.querySelector(options.selectorFileTemplate);

    let selectedFiles = [];

    if (el.dataset.initialized) {
        return;
    }
    el.dataset.initialized = "true";

    fileUpload.addEventListener("change", () => {
        selectedFiles = Array.from(fileUpload.files);
        handleFilesChange(selectedFiles);
    });

    uploadButton.addEventListener("click", (event) => {
        fileUpload.click();
        event.stopPropagation();
    });

    uploadButton.addEventListener("dragover", (event) => {
        event.preventDefault();
        uploadButton.classList.add("active");
    });

    uploadButton.addEventListener("dragleave", () => {
        uploadButton.classList.remove("active");
    });

    uploadButton.addEventListener("drop", (event) => {
        event.preventDefault();
        uploadButton.classList.remove("active");

        selectedFiles = Array.from(event.dataTransfer.files);
        handleFilesChange(selectedFiles);

        // Aktualisieren des fileUpload-Elements
        const dataTransfer = new DataTransfer();
        selectedFiles.forEach((file) => {
            dataTransfer.items.add(file);
        });
        fileUpload.files = dataTransfer.files;

        resetFileInput();
    });

    function handleFilesChange(files) {
        selectedFilesList.innerHTML = "";
        const dataTransfer = new DataTransfer();

        files.forEach((file, i) => {
            const clone = document.importNode(fileTemplate.content, true);
            const fileNameElement = clone.querySelector(
                options.selectorFileName
            );
            const fileSizeElement = clone.querySelector(
                options.selectorFileSize
            );

            const currentDeleteButton = clone.querySelector(".delete--button");
            currentDeleteButton.addEventListener("click", function () {
                selectedFiles = selectedFiles.filter((_, index) => index !== i);
                handleFilesChange(selectedFiles);
                resetFileInput();
                if (selectedFiles.length === 0) {
                    resetFileInput();
                } else {
                    updateFileInput(selectedFiles);
                }

                if (selectedFiles.length === 0) {
                    uploadButton.style.display = "";
                }
            });

            dataTransfer.items.add(file);

            fileNameElement.textContent = file.name;
            fileSizeElement.textContent =
                " (" + formatFileSize(file.size) + ")";
            selectedFilesList.appendChild(clone);
        });

        fileUpload.files = dataTransfer.files;

        if (files.length === 0) {
            uploadButton.style.display = "block";
        } else {
            uploadButton.style.display = "none";
        }
    }

    function updateFileInput(files) {
        const newFileUpload = fileUpload.cloneNode();
        const dataTransfer = new DataTransfer();
        files.forEach((file) => {
            dataTransfer.items.add(file);
        });
        newFileUpload.files = dataTransfer.files;
        fileUpload.parentNode.replaceChild(newFileUpload, fileUpload);
        fileUpload = newFileUpload;
        fileUpload.addEventListener("change", () => {
            selectedFiles = Array.from(fileUpload.files);
            handleFilesChange(selectedFiles);
        });
    }

    function formatFileSize(bytes) {
        if (bytes < 1024) return bytes + " Bytes";
        else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + " KB";
        else return (bytes / 1048576).toFixed(2) + " MB";
    }
}

if (document.querySelector(".field--upload-container")) {
    const elements = document.querySelectorAll(".field--upload-container");
    elements.forEach((element) => new UploadField(element));
}
