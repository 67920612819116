import Swiper, { Pagination, Autoplay, Navigation } from "swiper";

const heroBanner = document.querySelector(".hero");

if (heroBanner) {
    if (document.querySelector("body.neos-backend")) {
        const swiperImage = new Swiper(".stageimage--swiper", {
            modules: [Pagination, Autoplay, Navigation],
            allowTouchMove: false,
            watchOverflow: true,
            autoplay: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".right",
                prevEl: ".left",
            },
        });
    } else {
        if (
            document.querySelectorAll(".stageimage--swiper .swiper-slide")
                .length > 1
        ) {
            const swiperImage = new Swiper(".stageimage--swiper", {
                modules: [Pagination, Autoplay, Navigation],
                allowTouchMove: true,
                speed: 500,
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".right",
                    prevEl: ".left",
                },
            });
        }
    }

    const slidesCount = document.querySelectorAll(
        ".stageimage--swiper .swiper-slide"
    ).length;
    const leftButton = document.querySelector(".swiperbutton.left");
    const rightButton = document.querySelector(".swiperbutton.right");

    // Verstecken oder Anzeigen der Elemente abhängig von der Anzahl der Slides
    if (slidesCount <= 1) {
        leftButton.classList.add("hide-elements");
        rightButton.classList.add("hide-elements");
    } else {
        leftButton.classList.remove("hide-elements");
        rightButton.classList.remove("hide-elements");
    }
}
